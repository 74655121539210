import { useMediaQuery } from '@mui/material';

import { useBookmarkWarning } from 'context';
import useWalletContext from './useWalletContext';

const useTopValue = () => {
  const below1440 = useMediaQuery('(max-width:1439px)');
  const below1280 = useMediaQuery('(max-width:1279px)');
  const below600 = useMediaQuery('(max-width:599px)');

  const { wrongNetwork } = useWalletContext();
  const { isOpen: bookmarkWarning } = useBookmarkWarning();

  const bookmarkBarHeight = 42;
  const wrongNetworkBarHeight = 60;
  const navBarHeight = below1440 && !below1280 ? 48 : below600 ? 50 : 60;

  let marginTop = 0;
  let fixedTopPosition = 0;

  if (wrongNetwork && bookmarkWarning) {
    marginTop = bookmarkBarHeight + wrongNetworkBarHeight + navBarHeight;
    fixedTopPosition = bookmarkBarHeight + wrongNetworkBarHeight;
  } else if (wrongNetwork) {
    marginTop = wrongNetworkBarHeight + navBarHeight;
    fixedTopPosition = wrongNetworkBarHeight;
  } else if (bookmarkWarning) {
    marginTop = bookmarkBarHeight + navBarHeight;
    fixedTopPosition = bookmarkBarHeight;
  } else {
    marginTop = navBarHeight;
  }

  return { marginTop, fixedTopPosition };
};

export default useTopValue;
