import { useStateIfMounted } from 'use-state-if-mounted';
import { addHours, setHours, setMinutes } from 'date-fns';
import { Typography, styled, useTheme } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

import { LISTING_DURATIONS, NETWORK } from 'constants/index';
import useCreateMakeOfferOrder from 'hooks/useCreateMakeOfferOrder';
import useNotification from 'hooks/useNotification';
import useTokenContext from 'hooks/useTokenContext';
import useWalletContext from 'hooks/useWalletContext';
import { useGetExchangeApprovedWrapped, useApproveExchangeWrapped } from 'utils/onchain/exchange';

import {
  CheckoutModalRow,
  ModalHeaderContainer,
  ModalIconButton,
} from '../../components/styledComponents';
import { GeneralFlexBox } from 'components/StyledComponents';
import WrappedDetails from './WrappedDetails';
import OfferProcess, { OFFER_PROCESS_STEP } from './OfferProcess';
import OfferExpiration from './OfferExpiration';
import { StandardButton } from 'components/Button';
import ConnectWallet from '../../components/ConnectWallet';

const MainContainer = styled(GeneralFlexBox)(({ display }) => ({
  display,
  width: '100%',
  flexDirection: 'column',
  // Move footer row to bottom screen when in vertical small screen
  '@media(max-width: 600px)': {
    height: '100%',
    justifyContent: 'space-between',
  },
}));

const MiddleRow = styled(CheckoutModalRow)(() => ({
  flexGrow: 1,
  alignItems: 'flex-start',
  flexDirection: 'column',
}));

const CheckoutFooterRow = styled(CheckoutModalRow)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.border.separator}`,
}));

const currentDate = new Date();

/********************  Main Component  ********************/
const MakeOffer = ({
  data,
  closeModal,
  nativeBalance,
  wrappedBalance,
  lastPrice,
  setModal,
  visible = true,
}) => {
  const theme = useTheme();

  const showNotification = useNotification();
  const { connectedAndAuth } = useTokenContext();
  const { storedChain } = useWalletContext();
  const approveExchangeWrapped = useApproveExchangeWrapped();
  const getExchangeApprovedWrapped = useGetExchangeApprovedWrapped();

  const [panel, setPanel] = useStateIfMounted();
  const [error, setError] = useStateIfMounted('');
  const [bidAmount, setBidAmount] = useStateIfMounted('');
  const [wrappedFailed, setWrappedFailed] = useStateIfMounted(false);
  const [confirmFailed, setConfirmFailed] = useStateIfMounted(false);
  const [wrappedProcessing, setWrappedProcessing] = useStateIfMounted(false);
  const [showOfferProcess, setShowOfferProcess] = useStateIfMounted(false);
  const [confirmProcessing, setConfirmProcessing] = useStateIfMounted(false);
  const [expiredDate, setExpiredDate] = useStateIfMounted(LISTING_DURATIONS[5].value); // 6 months
  const [exactTime, setExactTime] = useStateIfMounted({
    hours: currentDate.getHours(),
    minutes: currentDate.getMinutes(),
  });

  const { lastOwnerAddress: owner, contractAddress, tokenId } = data || {};
  const disabled = !Boolean(Number(bidAmount));
  const network = NETWORK[storedChain];
  const wrappedCoin = network.wrapped;

  const dateWithExactTime = new Date(
    setMinutes(
      setHours(addHours(new Date(), expiredDate || 24), exactTime.hours),
      exactTime.minutes,
    ),
  );

  const timeRange = {
    start: parseInt(new Date().getTime() / 1000),
    end: parseInt(dateWithExactTime.getTime() / 1000),
  };

  const createOBOrder = useCreateMakeOfferOrder({
    value: bidAmount,
    owner,
    contractAddress,
    tokenId,
    timeRange,
  });

  const handleMakeOffer = () => {
    if (Boolean(bidAmount) && Number(bidAmount) > Number(wrappedBalance)) {
      setError(`Insufficient ${wrappedCoin} Balance`);
    } else if (Number(bidAmount) < network.minAmount) {
      setError(`You must offer a minimum amount of ${network.minAmount} ${wrappedCoin}`);
    } else {
      setError('');
      allowWrappedIfNeeded();
    }
  };

  const allowWrappedIfNeeded = async () => {
    setShowOfferProcess(true);
    setPanel(OFFER_PROCESS_STEP.WRAPPED_ENABLE);
    setWrappedFailed(false);
    setWrappedProcessing(true);
    const approvedWrappedAmount = await getExchangeApprovedWrapped();

    if (approvedWrappedAmount < bidAmount) {
      try {
        await approveExchangeWrapped();
        showNotification({ message: `${wrappedCoin} enabled`, type: 'success' });
        handleConfirmOffer();
      } catch (err) {
        showNotification({ message: err.reason, type: 'error' });
        setWrappedFailed(true);
      } finally {
        setWrappedProcessing(false);
      }
    } else {
      setWrappedProcessing(true);
      handleConfirmOffer();
    }
  };

  const handleConfirmOffer = async () => {
    setPanel(OFFER_PROCESS_STEP.CONFIRM_OFFER);
    setConfirmFailed(false);
    setConfirmProcessing(true);

    try {
      await createOBOrder();
      setPanel(OFFER_PROCESS_STEP.POST_OFFER);
      showNotification({
        message: 'Successfully made offer.',
        type: 'success',
      });
    } catch (err) {
      showNotification({
        message: err.reason || err.message,
        type: 'error',
      });
      setConfirmFailed(true);
    } finally {
      setConfirmProcessing(false);
    }
  };

  return (
    <MainContainer direction="column" width="100%" display={visible ? 'flex' : 'none'}>
      <ModalHeaderContainer>
        <Typography fontSize={showOfferProcess ? 20 : 24} fontWeight={showOfferProcess ? 600 : 500}>
          {!connectedAndAuth ? 'Connect wallet' : 'Make offer'}
        </Typography>
        <ModalIconButton onClick={closeModal}>
          <CloseIcon style={{ fill: theme.palette.icon.tertiary }} />
        </ModalIconButton>
      </ModalHeaderContainer>
      {!connectedAndAuth ? (
        <ConnectWallet />
      ) : showOfferProcess ? (
        <OfferProcess
          data={data}
          bidAmount={bidAmount}
          expiredDate={timeRange.end}
          closeModal={closeModal}
          panel={panel}
          wrappedFailed={wrappedFailed}
          onApproveWrapped={allowWrappedIfNeeded}
          wrappedProcessing={wrappedProcessing}
          confirmFailed={confirmFailed}
          confirmProcessing={confirmProcessing}
          onConfirmOffer={handleConfirmOffer}
        />
      ) : (
        <>
          <MiddleRow sx={{ padding: theme.spacing(3.7, 3, 3, 3) }}>
            <WrappedDetails
              data={data}
              nativeBalance={nativeBalance}
              wrappedBalance={wrappedBalance}
              lastPrice={lastPrice}
              setModal={setModal}
              onMakeOffer={handleMakeOffer}
              bidAmount={bidAmount}
              onBidAmountChanged={setBidAmount}
              setError={setError}
              error={error}
            />
            <OfferExpiration
              expiredDate={expiredDate}
              setExpiredDate={setExpiredDate}
              dateWithExactTime={dateWithExactTime}
              setExactTime={setExactTime}
            />
          </MiddleRow>
          <CheckoutFooterRow>
            <StandardButton
              variant="contained"
              width={199}
              height={48}
              onClick={handleMakeOffer}
              disabled={disabled}
              disableRipple
            >
              Make Offer
            </StandardButton>
          </CheckoutFooterRow>
        </>
      )}
    </MainContainer>
  );
};

export default MakeOffer;
