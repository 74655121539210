import { IconButton, styled, Typography } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { useStateIfMounted } from 'use-state-if-mounted';

import { WALLET_OPTIONS } from 'constants/index';
import useWalletContext from 'hooks/useWalletContext';
import useNotification from 'hooks/useNotification';
import useTokenContext from 'hooks/useTokenContext';

import { FlexBox, GeneralFlexBox } from 'components/StyledComponents';
import { CheckoutHeadingRow, CheckoutModalRow } from '../components/styledComponents';
import { StandardButton } from 'components/Button';

const MainContainer = styled(GeneralFlexBox)(({ theme }) => ({
  width: '100%',
  flexDirection: 'column',
  // Move footer row to bottom screen when in vertical small screen
  '@media(max-width: 500px)': {
    height: 'var(--vh, 1vh) * 100',
    justifyContent: 'space-between',
  },
  padding: theme.spacing(0, 2, 2),
}));

const ModalCloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(3),
  right: theme.spacing(3),
  color: theme.palette.text.tertiary,
  padding: 2,
  borderRadius: 4,

  '&:hover': {
    background: theme.palette.background.apply.hovered,
  },

  '&:focus': {
    background: theme.palette.background.apply.pressed,
  },

  '@media (max-width: 500px)': {
    top: theme.spacing(1.8),
  },
}));

const CustomButton = styled(StandardButton)(({ theme }) => ({
  width: '100%',
  height: 48,
  marginBottom: theme.spacing(2),
  justifyContent: 'flex-start',
}));

const WalletConnectors = ({ closeModal }) => {
  const { connectors, connectAsync, setWalletModalOpen } = useWalletContext();
  const { isAuthenticated } = useTokenContext();
  const showNotification = useNotification();

  const [loading, setLoading] = useStateIfMounted(false);

  const handleConnect = async (walletName) => {
    try {
      setLoading(true);
      const connector = connectors.find((connector) => connector.name === walletName);
      if (!connector && walletName === WALLET_OPTIONS[0].name) {
        setWalletModalOpen(true);
        closeModal();
        return;
      }
      await connectAsync({ connector });
    } catch (error) {
      console.error(error);
      showNotification({
        type: 'error',
        message: error?.message?.split('.')[0] || 'Error connecting wallet',
      });
    } finally {
      setLoading(false);
      if (isAuthenticated) {
        closeModal();
      }
    }
  };

  return (
    <MainContainer direction="column" width="100%">
      <CheckoutHeadingRow>
        <Typography fontSize={24} fontWeight={600} lineHeight="32px">
          Connect Wallet
        </Typography>
        <ModalCloseButton onClick={closeModal}>
          <CloseIcon />
        </ModalCloseButton>
      </CheckoutHeadingRow>
      <CheckoutModalRow>
        <Typography>Choose a wallet to connect</Typography>
      </CheckoutModalRow>
      <GeneralFlexBox padding="0px 32px" direction="column" width="100%">
        {WALLET_OPTIONS.map((wallet) => (
          <CustomButton
            key={wallet.name}
            onClick={() => handleConnect(wallet.name)}
            disabled={loading}
          >
            <FlexBox sx={{ gap: 1 }}>
              {wallet.icon}
              {wallet.name.toUpperCase()}
            </FlexBox>
          </CustomButton>
        ))}
      </GeneralFlexBox>
    </MainContainer>
  );
};

export default WalletConnectors;
