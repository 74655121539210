import React, { useCallback, useEffect } from 'react';
import { useStateIfMounted } from 'use-state-if-mounted';
import { waitForTransactionReceipt } from '@wagmi/core';
import { getContract, parseUnits } from 'viem';
import { styled, useTheme } from '@mui/material';

import wagmiConfig from 'wagmiConfig';
import { WNRGAbi } from 'constants/abis';
import { NETWORK, REDIRECT_LINK_EXPLORER, WRAPPED_PROXY_ADDRESS } from 'constants/index';
import useBalanceContext from 'hooks/useBalanceContext';
import useNotification from 'hooks/useNotification';
import useWalletContext from 'hooks/useWalletContext';
import { calculateGas } from 'utils';
import { formatNumber, removeCommas } from 'utils/numbers';

import ReverseDark from 'assets/images/reverse.svg';
import ReverseLight from 'assets/images/reverse-light.svg';
import { GeneralFlexBox } from 'components/StyledComponents';
import { CheckoutModalRow } from '../../components/styledComponents';
import { CurrencyIcon } from 'components/Currency';
import { Divider } from 'components/Modal';
import ModalHeader from './ModalHeader';
import InputRow from './InputRow';
import RenderButton from './RenderButton';

/********************  Styled Components  ********************/
const MainContainer = styled(GeneralFlexBox)(() => ({
  width: '100%',
  flexDirection: 'column',

  '@media(max-width: 600px)': {
    height: '100%',
    justifyContent: 'space-between',
  },
}));

const CheckoutFooterRow = styled(CheckoutModalRow)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.border.separator}`,
  height: 96,
}));

const CheckoutModalArrowIcon = styled('img')(({ error }) => ({
  borderRadius: 8,
  position: 'absolute',
  width: 40,
  height: 40,
  top: error ? '48%' : '45%',
  cursor: 'pointer',
  transition: 'filter 0.3s ease-in-out',

  '&:hover': {
    filter: 'invert(10%)',
  },
}));

/********************  Main Component  ********************/
const ConvertCurrency = ({ prevModal, onBack, closeModal }) => {
  const theme = useTheme();

  const { wrappedBalance, nativeBalance, coinLastPrice, getTokenBalances } = useBalanceContext();
  const { address, storedChain, signer } = useWalletContext();
  const showNotification = useNotification();

  const [amount, setAmount] = useStateIfMounted('');
  const [usdAmount, setUsdAmount] = useStateIfMounted('0.00');
  const [error, setError] = useStateIfMounted(null);
  const [converting, setConverting] = useStateIfMounted(false);

  const currency = NETWORK[storedChain];

  const [fromInputData, setFromInputData] = useStateIfMounted({
    token: currency.wrapped,
    balance: wrappedBalance ?? '0.00',
    icon: <CurrencyIcon type="current" />,
  });

  const [toInputData, setToInputData] = useStateIfMounted({
    token: currency.symbol,
    balance: nativeBalance,
    icon: <CurrencyIcon />,
  });

  useEffect(() => {
    setFromInputData({ ...fromInputData, balance: wrappedBalance });
  }, [wrappedBalance]);

  useEffect(() => {
    setToInputData({ ...toInputData, balance: nativeBalance });
  }, [nativeBalance]);

  const swapInputData = () => {
    const _tokenFrom = fromInputData;
    const _tokenTo = toInputData;
    setFromInputData(_tokenTo);
    setToInputData(_tokenFrom);
    handleError(amount, _tokenTo);
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setAmount(value);
    const usdETH = formatNumber(value * coinLastPrice) || '0.00';
    setUsdAmount(usdETH);
    handleError(value, fromInputData);
  };

  const handleMaxAmount = () => {
    const maxAmount = removeCommas(fromInputData.balance);
    setAmount(maxAmount);
    const usdETH = formatNumber(maxAmount * coinLastPrice) || '0.00';
    setUsdAmount(usdETH);
  };

  const handleError = (value, input) => {
    if (Number(value) > Number(removeCommas(input.balance))) {
      setError(`Insufficient ${input.token} Balance`);
    } else {
      setError(false);
    }
  };

  const handleSwapTokens = useCallback(async () => {
    if (amount && Number(amount) < currency.minAmount) {
      return setError(
        `You must offer a minimum amount of ${currency.minAmount} ${currency.symbol}`,
      );
    }

    const amountInWei = parseUnits(amount, 18);

    try {
      const contract = getContract({
        address: WRAPPED_PROXY_ADDRESS[storedChain],
        abi: WNRGAbi.abi,
        client: { wallet: signer },
      });

      setConverting(true);

      let txHash;
      if (fromInputData.token === currency.wrapped) {
        const gasEstimate = await contract.estimateGas.withdraw([amountInWei]);
        txHash = await contract.write.withdraw([amountInWei], { gas: calculateGas(gasEstimate) });
      }
      if (fromInputData.token === currency.symbol) {
        const gasEstimate = await contract.estimateGas.deposit({
          from: address,
          value: amountInWei,
        });
        txHash = await contract.write.deposit({
          from: address,
          value: amountInWei,
          gas: calculateGas(gasEstimate),
        });
      }

      showNotification({
        message: 'Transaction pending',
        type: 'pending',
        link: `${REDIRECT_LINK_EXPLORER[storedChain]}/tx/${txHash}`,
      });

      const receipt = await waitForTransactionReceipt(wagmiConfig, { hash: txHash });
      if (!receipt) {
        showNotification({
          message: 'Conversion failed',
          type: 'error',
        });
        return;
      }

      showNotification({
        message: 'Converted succesfully',
        type: 'success',
        link: `${REDIRECT_LINK_EXPLORER[storedChain]}/tx/${txHash}`,
      });
      onBack();
      getTokenBalances();
    } catch (error) {
      console.error('error', error);
      showNotification({ message: 'Transaction failed, please try again', type: 'error' });
    } finally {
      setConverting(false);
    }
  }, [amount, fromInputData, toInputData, signer]);

  return (
    <MainContainer direction="column" width="100%">
      <ModalHeader prevModal={prevModal} onBack={onBack} closeModal={closeModal} />

      <InputRow
        title="Convert from"
        inputData={fromInputData}
        amount={amount}
        usdAmount={usdAmount}
        handleChange={handleChange}
        error={error}
        handleMaxAmount={handleMaxAmount}
      />

      <CheckoutModalArrowIcon
        src={theme.palette.mode === 'light' ? ReverseLight : ReverseDark}
        onClick={swapInputData}
        error={error}
      />
      <Divider />

      <InputRow
        title="Convert to"
        inputData={toInputData}
        amount={amount}
        usdAmount={usdAmount}
        handleChange={handleChange}
      />

      <CheckoutFooterRow>
        <RenderButton
          converting={converting}
          onSwapTokens={handleSwapTokens}
          error={Boolean(error)}
        />
      </CheckoutFooterRow>
    </MainContainer>
  );
};

export default React.memo(ConvertCurrency);
