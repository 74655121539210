import React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import {
  Drawer as MuiDrawer,
  List as MuiList,
  InputAdornment,
  ListItemButton,
  Box,
  TextField,
  useMediaQuery,
  Button,
} from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';
import SearchResultContainer from './SearchResultContainer';

/********************  Styled Components  ********************/
const FilterMenuItem = styled(ListItemButton)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.secondary,
  color: theme.palette.text.main,
  lineHeight: '24px',
  letterSpacing: '0.15px',
  fontWeight: 600,
  boxShadow:
    '0px 0px 2px rgba(0, 0, 0, 0.14), 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)',

  '&:hover': {
    backgroundColor: theme.palette.background.secondary,
    opacity: 0.8,
  },
}));

const List = styled(MuiList)(({ theme }) => ({
  color: theme.palette.text.main,
  paddingTop: theme.spacing(0),
}));

const SearchFieldContainer = styled(Box)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  margin: theme.spacing(0, 1.5),
}));

const SearchTextField = styled(TextField)(({ theme }) => ({
  background: theme.palette.background.secondary,
  flex: 1,
  letterSpacing: '0.25px',
  width: '-webkit-fill-available',
  '& fieldset': {
    border: `1px solid ${theme.palette.border.decorative}`,
    borderRadius: 8,
  },
  '& input::placeholder': {
    color: theme.palette.text.secondary,
    opacity: 1,
  },
  '& input': {
    color: theme.palette.text.main,
    padding: theme.spacing(1.5, 1.5),
    width: '100%',
  },
}));

const CancelButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.placeholder,
  fontSize: 16,
  fontWeight: 600,
  marginLeft: theme.spacing(2),
}));

/************************* Main Component  **************************/
const SearchNavigation = ({
  open,
  onBack,
  onSearch,
  searchResult,
  isLoading,
  showSearchResultContainer,
}) => {
  const theme = useTheme();
  const below700 = useMediaQuery('(max-width: 700px)');
  const height = showSearchResultContainer ? '100%' : 70;

  return (
    <MuiDrawer
      sx={{
        width: '100%',
        height,
        '& .MuiDrawer-root': {
          height,
        },
        '& .MuiBackdrop-root': {
          backgroundColor: theme.palette.background.overlay,
          height,
        },
        '& .MuiDrawer-paper': {
          width: below700 ? '100%' : '60%',
          paddingTop: below700 ? 0 : 72,
          background: theme.palette.background.default,
          height,
          boxShadow: 'none',

          '&::-webkit-scrollbar': {
            display: 'none',
          },
        },
        '@media (max-width: 700px)': {
          zIndex: 1600, // to go over the header
        },
      }}
      anchor="right"
      open={open}
    >
      <List>
        <FilterMenuItem>
          <SearchFieldContainer>
            <SearchTextField
              placeholder="Search"
              icon={SearchIcon}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" style={{ color: theme.palette.icon.tertiary }}>
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => onSearch(e.target.value)}
            />
            <CancelButton onClick={onBack} variant="text">
              Cancel
            </CancelButton>
          </SearchFieldContainer>
        </FilterMenuItem>
      </List>
      {showSearchResultContainer && (
        <SearchResultContainer isLoading={isLoading} searchResult={searchResult} onClose={onBack} />
      )}
    </MuiDrawer>
  );
};

export default SearchNavigation;
