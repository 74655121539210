import { Box, Button, styled, useMediaQuery } from '@mui/material';
import {
  ArrowBack as ArrowBackIcon,
  ArrowForward as ArrowForwardIcon,
  Close as CloseIcon,
  FilterList as FilterListIcon,
} from '@mui/icons-material';

import useTopValue from 'hooks/useTopValue';

import { ToolTip } from 'components/StyledComponents';
import { FilterItem, FilterText, FilterIcon } from './Components';

const Drawer = styled(Box)(({ theme, open, hide, top }) => ({
  transition: '350ms ease',
  width: open ? 340 : hide ? 0 : 72,
  minWidth: open ? 340 : hide ? 0 : 72,
  borderRight: hide && !open ? '' : `1px solid ${theme.palette.border.divider}`,
  overflow: 'hidden',
  overflowY: 'auto',
  backgroundColor: theme.palette.background.default,
  zIndex: open ? 1250 : '1199 !important',

  '@media (max-width: 1024px)': {
    zIndex: 1250,
    position: open ? 'fixed' : 'absolute',
    height: open ? '100%' : 'auto',
    top: top,
    left: 0,
    height: open ? 'calc(100% - 60px)' : 'auto',
  },
  '@media (max-width: 480px)': {
    position: 'fixed',
    display: open ? 'block' : 'none',
    width: '100%',
  },
}));

const ExpandButton = styled(Button)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(2),
  '&:hover': {
    backgroundColor: 'transparent',
  },
  '& svg': {
    color: theme.palette.icon.tertiary,
  },
}));

const DrawerBackground = styled(Box)(() => ({
  width: '100%',
  height: '100%',
  position: 'fixed',
  left: 0,
  top: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.8)',
  zIndex: 1240,
  display: 'none',
  '@media (min-width: 480px) and (max-width: 1024px)': {
    display: 'block',
  },
}));

const FiltersMobileButton = styled(Button)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 600,
  color: theme.palette.active,
  minWidth: 120,
  minHeight: 48,
  margin: theme.spacing(0, 1),
}));

const FilterSectionFooter = styled(Box)(({ theme }) => ({
  minHeight: 60,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  borderTop: `1px solid ${theme.palette.border.divider}`,
}));

const FiltersContainer = styled(Box)(({}) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
}));

const FilterSideBar = ({ open, toggle, filters, hidden }) => {
  const below1024 = useMediaQuery('(max-width: 1024px)');
  const { marginTop } = useTopValue();

  return (
    <>
      <Drawer open={open ? 'open' : ''} hide={hidden?.toString()} top={marginTop}>
        <FiltersContainer>
          <Box flex={1}>
            <Box display={open ? 'none' : 'block'}>
              {!open && !hidden && (
                <ToolTip title="Open filters" placement="right">
                  <ExpandButton disableRipple onClick={toggle}>
                    <ArrowForwardIcon />
                  </ExpandButton>
                </ToolTip>
              )}
            </Box>
            <Box display={open ? 'block' : 'none'}>
              <FilterItem onClick={toggle} disableRipple={true}>
                <FilterIcon>
                  <FilterListIcon />
                </FilterIcon>
                <FilterText>Filters</FilterText>
                <ToolTip title="Close filters" placement="right">
                  {below1024 ? <CloseIcon /> : <ArrowBackIcon />}
                </ToolTip>
              </FilterItem>
              {filters}
            </Box>
          </Box>
          {open && below1024 && (
            <FilterSectionFooter>
              <FiltersMobileButton onClick={toggle}>Close</FiltersMobileButton>
              <FiltersMobileButton>Clear All</FiltersMobileButton>
            </FilterSectionFooter>
          )}
        </FiltersContainer>
      </Drawer>
      {open && <DrawerBackground />}
    </>
  );
};

export default FilterSideBar;
