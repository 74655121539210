import { useMemo } from 'react';
import { useStateIfMounted } from 'use-state-if-mounted';
import {
  Box,
  Drawer,
  IconButton,
  InputAdornment,
  styled,
  TextField,
  useTheme,
} from '@mui/material';
import { Close as CloseIcon, Search as SearchIcon } from '@mui/icons-material';
import debounce from 'lodash.debounce';

import searchService from 'services/search';

import { SearchResultContainer } from '.';

const SearchFieldContainer = styled(Box)(({ theme }) => ({
  flex: 1,
  position: 'fixed',
  padding: '0 !important',
  width: '100%',
  borderBottom: `1px solid ${theme.palette.border.separator}`,
}));

const SearchTextField = styled(TextField)(({ theme }) => ({
  background: theme.palette.background.secondary,
  flex: 1,
  letterSpacing: '0.25px',
  width: '100%',
  height: 50,
  '& fieldset': {
    border: 'none',
    borderRadius: 8,
  },
  '& input::placeholder': {
    fontSize: 16,
    fontWeight: 400,
    color: theme.palette.text.secondary,
    opacity: 1,

    '@media (max-width: 1023px)': {
      fontSize: 13,
    },
  },
  '& input': {
    color: theme.palette.text.main,
    fontSize: 16,
    fontWeight: 400,
    padding: 0,
    width: '100%',
    height: 50,

    '@media (max-width: 1023px)': {
      fontSize: 13,
    },
    '@media (max-width: 904px)': {
      fontSize: 16,
    },
  },
  '&:has(input:focus-within) + div': {
    display: 'block',
  },

  '&:hover': {
    background: theme.palette.background.apply.hovered,
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.border.decorative,
    },
  },
  '&:active': {
    background: theme.palette.background.apply.pressed,
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.border.decorative,
    },
  },
}));

const SearchDrawer = ({ open, onClose }) => {
  const theme = useTheme();

  const [isLoading, setIsLoading] = useStateIfMounted(false);
  const [error, setError] = useStateIfMounted(false);
  const [searchText, setSearchText] = useStateIfMounted('');
  const [searchResult, setSearchResult] = useStateIfMounted({});

  const handleSearch = (input) => {
    setSearchText(input);
    setIsLoading(true);
    setError(false);
    searchService
      .search(input)
      .then((response) => {
        setSearchResult(response);
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setError(true);
        setIsLoading(false);
      });
  };

  const debouncedChangeHandler = debounce(handleSearch, 500);

  const showSearchResultContainer = useMemo(
    () => searchText && searchText.length > 2,
    [searchText],
  );

  return (
    <Drawer anchor="top" open={open} onClose={onClose} sx={{ zIndex: 1320 }}>
      <SearchFieldContainer>
        <SearchTextField
          placeholder="Search"
          icon={SearchIcon}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" style={{ color: theme.palette.icon.tertiary }}>
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={onClose}>
                  <CloseIcon style={{ color: theme.palette.icon.tertiary }} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          onChange={(e) => {
            debouncedChangeHandler(e.target.value);
          }}
        />
        {showSearchResultContainer && (
          <SearchResultContainer
            isLoading={isLoading}
            searchResult={searchResult}
            setSearchText={setSearchText}
            onClose={() => {
              setSearchText('');
              setSearchResult([]);
            }}
            isError={error}
          />
        )}
      </SearchFieldContainer>
    </Drawer>
  );
};

export default SearchDrawer;
