import { getFullMediaUrl } from './image';

export const formatResponse = (response, coinPrice) =>
  response.data.map((row) => {
    const circulatingSupply = row.totalMinted - row.totalBurned;
    let marketCap = null,
      marketCapInFiat = null;
    if (row.floorPrice !== null && circulatingSupply !== null) {
      marketCap = Number(row.floorPrice) * circulatingSupply;
      marketCapInFiat = marketCap * coinPrice;
    }
    // TODO: row.createdAt should be replaced with creationDate
    const collectionAgeInDays = (new Date() - new Date(row.createdAt)) / (1000 * 60 * 60 * 24);
    const volumeInFiat = Number(row.sumVolume) * coinPrice;

    return {
      ...row,
      contractImagePathName: getFullMediaUrl(row.contractImagePathName),
      contractThumbnailPathName: getFullMediaUrl(row.contractThumbnailPathName),
      marketCap,
      marketCapInFiat,
      sevenDayPrices: row.sevenDayPrices?.map(({ price }) => Number(price)),
      floorPriceInFiat: row.floorPrice !== null ? Number(row.floorPrice * coinPrice).toFixed(2) : 0,
      isNew: collectionAgeInDays < 1, // new is less than 24h
      confirmed: false, // TODO: fetch from BE when ready
      circulatingSupply: circulatingSupply ?? 0, // TODO: to be decided
      volumeInFiat,
    };
  });
