import React, { createContext, useReducer, useCallback, useEffect } from 'react';

import userService from 'services/user';
import collection from 'services/collection';

import { usePriceContext } from './PriceContext';
import useWalletContext from 'hooks/useWalletContext';
import useTokenContext from 'hooks/useTokenContext';
import { formatResponse } from 'utils/collections';

const initialState = {
  favoriteCollections: [],
  loading: true,
  view: localStorage.getItem('collectionsView') || 'expanded',
};

const ACTIONS = {
  SET_LOADING: 'SET_LOADING',
  SET_FAVORITE_COLLECTIONS: 'SET_FAVORITE_COLLECTIONS',
  ADD_FAVORITE_COLLECTION: 'ADD_FAVORITE_COLLECTION',
  REMOVE_FAVORITE_COLLECTION: 'REMOVE_FAVORITE_COLLECTION',
  SET_VIEW: 'SET_VIEW',
};

const CollectionsReducer = (state, action = null) => {
  switch (action.type) {
    case ACTIONS.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case ACTIONS.SET_FAVORITE_COLLECTIONS:
      return {
        ...state,
        loading: false,
        favoriteCollections: action.payload,
      };
    case ACTIONS.ADD_FAVORITE_COLLECTION:
      return {
        ...state,
        favoriteCollections: [...state.favoriteCollections, action.payload],
      };
    case ACTIONS.REMOVE_FAVORITE_COLLECTION:
      return {
        ...state,
        favoriteCollections: state.favoriteCollections.filter(
          (item) => item.address !== action.payload,
        ),
      };
    case ACTIONS.SET_VIEW:
      return {
        ...state,
        view: action.payload,
      };
    default:
      throw Error('Unknown action: ' + action.type);
  }
};

export const CollectionsContext = createContext(null);

export const CollectionsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(CollectionsReducer, initialState);
  const { connectedAndAuth } = useTokenContext();
  const { address } = useWalletContext();
  const { coinPrice } = usePriceContext();

  const setLoading = (value) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: value });
  };

  const setFavoriteCollections = (collections) => {
    dispatch({ type: ACTIONS.SET_FAVORITE_COLLECTIONS, payload: collections });
  };

  const addFavoriteCollection = async (collection) => {
    dispatch({ type: ACTIONS.ADD_FAVORITE_COLLECTION, payload: collection });

    await userService.favoriteCollection({ tokenAddress: collection.address });
    await getMyFavoriteCollections();
  };

  const removeFavoriteCollection = async (address) => {
    dispatch({ type: ACTIONS.REMOVE_FAVORITE_COLLECTION, payload: address });

    await userService.unfavoriteCollection({ tokenAddress: address });
    await getMyFavoriteCollections();
  };

  const setView = (view) => {
    localStorage.setItem('collectionsView', view);
    dispatch({ type: ACTIONS.SET_VIEW, payload: view });
  };

  const getMyFavoriteCollections = useCallback(async () => {
    setLoading(true);
    const response = (await collection.getFavoriteCollections(address)) || { data: [] };
    const formattedResponse = formatResponse(response, coinPrice);
    setFavoriteCollections(formattedResponse);
    setLoading(false);
  }, [address, state.favoriteCollections]);

  useEffect(() => {
    if (!connectedAndAuth) {
      setFavoriteCollections([]);
      return;
    }

    getMyFavoriteCollections();
  }, [address, connectedAndAuth]);

  return (
    <CollectionsContext.Provider
      value={{
        state,
        dispatch,
        setLoading,
        addFavoriteCollection,
        removeFavoriteCollection,
        getMyFavoriteCollections,
        setView,
      }}
    >
      {children}
    </CollectionsContext.Provider>
  );
};
