import React from 'react';
import { styled, Typography, useTheme, Box } from '@mui/material';
import { GeneralFlexBox } from 'components/StyledComponents';
import { Badge } from 'pages/Collections/Rankings/components';
import { ReactComponent as NoImage600 } from 'assets/images/noImage600.svg';
import { ReactComponent as NoImage600Light } from 'assets/images/noImage600Light.svg';
import { ReactComponent as ConfirmedIcon } from 'assets/images/collectionConfirmed.svg';

const Card = styled('div')(({ theme }) => ({
  border: `1px solid ${theme.palette.border.separator}`,
  borderRadius: 10,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  overflow: 'hidden',
  width: 430,
  maxWidth: 480,
  height: 355,

  '@media(max-width: 905px)': {
    height: 401,
    width: '100%',
  },
  '@media(max-width: 600px)': {
    height: 400,
  },
  '@media(max-width: 480px)': {
    height: 392,
  },

  '&:hover': {
    background: theme.palette.background.apply.hovered,
  },
  '&:active': {
    background: theme.palette.background.apply.pressed,
  },
}));

const FlexCol = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  alignItems: 'center',
  transform: 'translateY(-13%)',

  '@media(max-width: 905px)': {
    transform: 'translateY(-10%)',
  },
}));

const CollectionCard = ({ name, creator, description }) => {
  const theme = useTheme();

  return (
    <Card>
      <Box height={180}>
        {theme.palette.mode === 'light' ? (
          <NoImage600Light height={180} />
        ) : (
          <NoImage600 height={180} />
        )}
      </Box>
      <FlexCol>
        <Box sx={{ transform: 'scale(1.2)', mb: '15px' }}>
          <Badge />
        </Box>
        <GeneralFlexBox gap={3} direction="column" align="center" padding="0 16px 16px 16px">
          <GeneralFlexBox gap={6}>
            <Typography fontWeight={600}>{name}</Typography>
            <ConfirmedIcon width={16} height={15.35} />
          </GeneralFlexBox>
          <GeneralFlexBox gap={4} sx={{ marginBottom: '20px' }}>
            <Typography fontSize={12}>by</Typography>
            <span style={{ fontSize: 12, fontWeight: 500, color: theme.palette.link.primary }}>
              {creator || 'StreetArts'}
            </span>
          </GeneralFlexBox>
          <Typography
            sx={{
              color: theme.palette.text.secondary,
              fontWeight: 500,
              textAlign: 'center',
            }}
          >
            {description ||
              'Energi World is a collection of 1,234 generative worlds inspired by the Energi cryptocurrency.'}
          </Typography>
        </GeneralFlexBox>
      </FlexCol>
    </Card>
  );
};

export default CollectionCard;
