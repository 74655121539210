import { UserApi } from 'clientSDK';
import dotenv from 'dotenv';
dotenv.config();

const userApi = new UserApi();

export default {
  getUser: async (address) => {
    try {
      const userData = await userApi.getUser(address);
      return userData;
    } catch (err) {
      console.error(err);
    }
  },

  updateUser: async (newData) => {
    try {
      const responseData = await userApi.updateUser(newData);
      return responseData;
    } catch (err) {
      return err.response.data;
    }
  },

  getNonce: async (address) => {
    try {
      const nonceData = await userApi.getNonce(address);
      return nonceData;
    } catch (err) {
      return err.response.data;
    }
  },

  authenticate: async (address, signature) => {
    try {
      const body = { address, signature };
      const responseData = userApi.authenticate(body);
      return responseData;
    } catch (err) {
      console.error(err);
    }
  },

  logout: async () => {
    try {
      const logoutReponseData = await userApi.logout();

      return logoutReponseData;
    } catch (err) {
      console.error(err);
    }
  },

  favoriteCollection: (body) => userApi.favoriteCollection(body),

  unfavoriteCollection: (body) => userApi.unfavoriteCollection(body),

  getReceivedOffers: async ({ address, skip, limit }) => {
    try {
      const response = await userApi.getReceivedOffers({ address, skip, limit });
      return response;
    } catch (err) {
      console.error(err);
    }
  },

  lockAccount: async () => await userApi.lockAccount(),

  applyReferral: async (userAddress, referralAddress) => {
    try {
      const body = { userAddress, referralAddress };
      const response = await userApi.applyReferral(body);
      return response;
    } catch (err) {
      return err.response.data;
    }
  },

  getReferralReward: async () => await userApi.getReferralReward(),
};
