import { useContext } from 'react';
import { styled } from '@mui/material';

import { CheckoutModalContext } from 'pages/context';

import { StandardButton } from './Button';

const StyledButton = styled(StandardButton)(({ theme }) => ({
  '@media (max-width: 599px)': {
    padding: theme.spacing(1, 1),
  },
}));

export const ConnectButton = ({ width, height, disabled, onClick, children }) => {
  const { openModal } = useContext(CheckoutModalContext);

  const handleClick = async () => {
    openModal({ metadata: {} }, 'select_wallet', 'sign');
  };

  return (
    <>
      <StyledButton
        width={width ?? '100%'}
        height={height ?? 48}
        disabled={disabled}
        onClick={onClick ?? handleClick}
      >
        {children || 'Connect Wallet'}
      </StyledButton>
    </>
  );
};
