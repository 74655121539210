import { useEffect, useMemo } from 'react';
import { Box, MenuItem, Select, styled, useTheme } from '@mui/material';
import { useHistory } from 'react-router-dom';

import { NETWORK_SELECTION, SUPPORTED_NETWORKS } from 'constants/index';
import useWalletContext from 'hooks/useWalletContext';

const NetworkIcon = styled(Box)(({ color }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 37,
  minWidth: 48,
  borderRadius: 0,
  backgroundColor: color,

  '& svg': {
    width: 28,
    height: 28,
    '& path': { fill: 'white' },
  },
}));

const NetworkSelect = () => {
  const theme = useTheme();

  const {
    isConnected,
    globalChain,
    chainId,
    storedChain,
    updateStoredChain,
    switchToNetwork,
    setWrongNetwork,
  } = useWalletContext();
  const history = useHistory();

  const handleToggleNetwork = async (targetChain) => {
    try {
      if (globalChain !== targetChain) {
        let switchResult;
        if (isConnected) {
          switchResult = await switchToNetwork(targetChain);
        }
        if (switchResult?.id === targetChain) {
          updateStoredChain(targetChain);
          return;
        }

        updateStoredChain(targetChain);
        history.push('/collections?type=top');
        window.location.reload();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const selectedNetwork = NETWORK_SELECTION.find((item) => item.value === storedChain);
  const NETWORK_FILL = {
    [SUPPORTED_NETWORKS.Energi]: theme.palette.primary.main,
    [SUPPORTED_NETWORKS.Ethereum]: theme.palette.background.ethereum,
  };

  const SelectedIcon = useMemo(
    () => (
      <NetworkIcon color={NETWORK_FILL[selectedNetwork.value]}>{selectedNetwork.icon}</NetworkIcon>
    ),
    [selectedNetwork],
  );

  useEffect(() => {
    if (isConnected && chainId !== storedChain) {
      const supportedChainIds = Object.values(SUPPORTED_NETWORKS);
      if (supportedChainIds.includes(chainId)) {
        localStorage.setItem('storedChain', chainId);
        setWrongNetwork(false);
        history.push('/collections?type=top');
        window.location.reload();
        return;
      }
      setWrongNetwork(true);
    } else {
      setWrongNetwork(false);
    }
  }, [isConnected, chainId, storedChain]);

  return (
    <Select
      variant="standard"
      value={storedChain}
      disableUnderline
      IconComponent={() => null}
      onChange={(event) => handleToggleNetwork(event.target.value)}
      renderValue={() => SelectedIcon}
      sx={{
        height: '100%',
        width: '48px',
        '& .MuiSelect-select': {
          padding: 0,
          display: 'flex',
          alignItems: 'center',
        },
      }}
      inputProps={{
        MenuProps: {
          disableScrollLock: true,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          MenuListProps: {
            sx: {
              padding: 0,
              borderRadius: '8px',
            },
          },
          PaperProps: {
            sx: {
              borderRadius: '8px',
              border: `1px solid ${theme.palette.border.separator}`,
            },
          },
        },
      }}
    >
      {NETWORK_SELECTION.map((network) => (
        <MenuItem
          key={network.value}
          value={network.value}
          sx={{
            backgroundColor: `${
              network.value === selectedNetwork.value
                ? theme.palette.background.select
                : theme.palette.background.default
            } !important`,

            '&: hover': {
              backgroundColor: `${
                network.value !== selectedNetwork.value && theme.palette.background.hover
              } !important`,
            },
            '& svg': {
              width: 28,
              height: 28,
              '& path': { fill: NETWORK_FILL[network.value] },
            },
          }}
        >
          {network.icon} {network.label}
        </MenuItem>
      ))}
    </Select>
  );
};

export default NetworkSelect;
