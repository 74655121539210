import { createContext, useCallback, useEffect, useMemo, useReducer } from 'react';

import stats from 'services/stat';
import marketplace from 'services/marketplace';

import { ACTIVITY_TYPES } from 'constants/index';
import useTokenContext from 'hooks/useTokenContext';

const initialState = {
  total: null,
  items: [],
  perPage: 25,
  page: 1,
  loading: true,
  filters: [],
  infiniteScrollLoading: false,
  contractAddress: null,
  address: null,
  filteredCollection: null,
};

const ACTIONS = {
  SET_LOADING: 'SET_LOADING',
  SET_INITIAL_DATA: 'SET_INITIAL_DATA',
  SET_PAGE: 'SET_PAGE',
  ADD_ITEMS: 'ADD_ITEMS',
  SET_PER_PAGE: 'SET_PER_PAGE',
  SET_FILTERS: 'SET_FILTERS',
  SET_INFINITE_SCROLL_LOADING: 'SET_INFINITE_SCROLL_LOADING',
  SET_CONTRACT_ADDRESS: 'SET_CONTRACT_ADDRESS',
  SET_ADDRESS: 'SET_ADDRESS',
  SET_FILTERED_COLLECTION: 'SET_FILTERED_COLLECTION',
};

const ActivityReducer = (state, action = null) => {
  switch (action.type) {
    case ACTIONS.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case ACTIONS.SET_ITEMS:
      return {
        ...state,
        loading: false,
        items: action.payload.data,
        total: action.payload.totalItems,
      };
    case ACTIONS.SET_PAGE:
      return {
        ...state,
        page: action.payload,
      };
    case ACTIONS.ADD_ITEMS:
      return {
        ...state,
        items: [...state.items, ...action.payload.data],
        total: action.payload.totalItems,
      };
    case ACTIONS.SET_PER_PAGE:
      return {
        ...state,
        perPage: action.payload,
      };
    case ACTIONS.SET_FILTERS:
      return {
        ...state,
        filters: action.payload,
      };
    case ACTIONS.SET_INFINITE_SCROLL_LOADING:
      return {
        ...state,
        infiniteScrollLoading: action.payload,
      };
    case ACTIONS.SET_CONTRACT_ADDRESS:
      return {
        ...state,
        contractAddress: action.payload,
      };
    case ACTIONS.SET_ADDRESS:
      return {
        ...state,
        address: action.payload,
      };
    case ACTIONS.SET_FILTERED_COLLECTION:
      return {
        ...state,
        filteredCollection: action.payload,
      };
  }

  throw Error('Unknown action: ' + action.type);
};

export const ActivityContext = createContext(null);

export const ActivityProvider = ({ children }) => {
  const [state, dispatch] = useReducer(ActivityReducer, initialState);
  const { connectedAndAuth } = useTokenContext();

  const setLoading = (value) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: value });
  };

  const parsedFilters = useMemo(() => {
    if (Boolean(state.filters.length)) {
      return Object.fromEntries(
        Object.keys(ACTIVITY_TYPES).map((item) => [item, state.filters.includes(item)]),
      );
    }
    return {};
  }, [state.filters]);

  const fetchActivity = async () => {
    setLoading(true);
    const response = await stats.getActivities({
      skip: (state.page - 1) * state.perPage,
      limit: state.perPage,
      ...(state.filteredCollection ? { collection: state.filteredCollection.address } : {}),
      ...parsedFilters,
    });
    setItems(response);
  };

  const handleNextPage = async () => {
    setLoading(true);
    const response = await stats.getActivities({
      skip: state.page * state.perPage,
      limit: state.perPage,
      ...(state.filteredCollection ? { collection: state.filteredCollection.address } : {}),
      ...parsedFilters,
    });
    setItems(response);
    setPage(state.page + 1);
    setLoading(false);
  };

  const handlePrevPage = async () => {
    setLoading(true);
    const response = await stats.getActivities({
      skip: (state.page - 2) * state.perPage,
      limit: state.perPage,
      ...parsedFilters,
    });
    setItems(response);
    setPage(state.page - 1);
    setLoading(false);
  };

  const fetchUserActivity = async () => {
    setLoading(true);
    const response = await stats.getUserActivities({
      address: state.address,
      skip: 0,
      limit: state.perPage,
      ...(state.filteredCollection ? { collection: state.filteredCollection.address } : {}),
      ...parsedFilters,
    });
    setItems(response);
  };

  const fetchMoreUserActivity = async (address) => {
    const response = await stats.getUserActivities({
      address,
      skip: state.items.length,
      limit: state.perPage,
      ...(state.filteredCollection ? { collection: state.filteredCollection.address } : {}),
      ...parsedFilters,
    });
    dispatch({ type: ACTIONS.ADD_ITEMS, payload: response });
    setInfiniteScrollLoading(false);
  };

  const fetchContractActivity = useCallback(async () => {
    setLoading(true);
    const response = await marketplace.getCollectionTradingHistory({
      contractAddress: state.contractAddress,
      limit: state.perPage,
      ...parsedFilters,
    });
    setItems(response);
  }, [state.contractAddress, state.page, state.perPage, parsedFilters]);

  const fetchMoreContractActivity = useCallback(async () => {
    const response = await marketplace.getCollectionTradingHistory({
      contractAddress: state.contractAddress,
      skip: state.items.length,
      limit: state.perPage,
      ...parsedFilters,
    });
    dispatch({ type: ACTIONS.ADD_ITEMS, payload: response });
    setInfiniteScrollLoading(false);
  }, [state.contractAddress, state.items, state.perPage, parsedFilters]);

  const setItems = (items) => {
    dispatch({ type: ACTIONS.SET_ITEMS, payload: items });
  };

  const setPage = (page) => {
    dispatch({ type: ACTIONS.SET_PAGE, payload: page });
  };

  const setPerPage = (value) => {
    dispatch({ type: ACTIONS.SET_PER_PAGE, payload: value });
  };

  const setFilters = (value) => {
    setPage(1);
    dispatch({ type: ACTIONS.SET_FILTERS, payload: value ?? [] });
  };

  const setInfiniteScrollLoading = (value) => {
    dispatch({ type: ACTIONS.SET_INFINITE_SCROLL_LOADING, payload: value });
  };

  const setContractAddress = (value) => {
    dispatch({ type: ACTIONS.SET_CONTRACT_ADDRESS, payload: value });
  };

  const setFilteredCollection = (collection) => {
    dispatch({ type: ACTIONS.SET_FILTERED_COLLECTION, payload: collection });
  };

  const setAddress = (address) => {
    dispatch({ type: ACTIONS.SET_ADDRESS, payload: address });
  };

  useEffect(() => {
    if (state.contractAddress) {
      fetchContractActivity();
    }
  }, [state.contractAddress, parsedFilters]);

  useEffect(() => {
    if (state.address) {
      fetchUserActivity();
    }
  }, [state.address, state.filteredCollection, parsedFilters]);

  useEffect(() => {
    if (connectedAndAuth === false) {
      setLoading(false);
    }
  }, [connectedAndAuth]);

  return (
    <ActivityContext.Provider
      value={{
        state,
        dispatch,
        setLoading,
        handleNextPage,
        handlePrevPage,
        setPerPage,
        setPage,
        setFilters,
        parsedFilters,
        fetchUserActivity,
        fetchActivity,
        setInfiniteScrollLoading,
        fetchMoreUserActivity,
        setContractAddress,
        fetchContractActivity,
        fetchMoreContractActivity,
        setFilteredCollection,
        setAddress,
      }}
    >
      {children}
    </ActivityContext.Provider>
  );
};
