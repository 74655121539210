import makeBlockie from 'ethereum-blockies-base64';
import { styled } from '@mui/material';

import { ZERO_ADDRESS } from 'constants/index';
import { useUserContext } from 'context';
import useWalletContext from 'hooks/useWalletContext';
import { ETH_ADDRESS_VALIDATOR } from 'utils/validators';

/********************  Styled Components  ********************/
const BlockieContainer = styled('div')(({ size }) => ({
  width: size ?? 32,
  height: size ?? 32,
  marginRight: 10,
  borderRadius: 100,
  display: 'flex',
  alignItems: 'center',
  overflow: 'hidden',

  '& > img': {
    width: size ?? 32,
    height: size ?? 32,
    objectFit: 'cover',
  },
  '@media (max-width: 599px)': {
    marginRight: 0,
  },
}));

/********************  Sub Component  ********************/
const ProfileImage = ({ avatar }) => {
  const { address } = useWalletContext();
  const { profilePicture } = useUserContext();

  const isValidAddress = (address) => address && ETH_ADDRESS_VALIDATOR.test(address);

  // Displays the avatar if its a link
  if (avatar && !isValidAddress(avatar)) {
    return <img src={avatar} />;
  }

  if (profilePicture && avatar === address) {
    return <img src={profilePicture} />;
  }

  // Displays the blockie if the avatar is an address
  return <img src={makeBlockie(avatar ?? address ?? ZERO_ADDRESS)} />;
};

/********************  Main Component  ********************/
const Blockie = ({ size, avatar }) => (
  <BlockieContainer size={size}>
    <ProfileImage avatar={avatar} />
  </BlockieContainer>
);

export default Blockie;
